import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { BreadcrumbNavigation, BreadcrumbItem } from '@entur/menu';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<BreadcrumbNavigation>\n  <BreadcrumbItem as={Link} to=\"/\">\n    Hjem\n  </BreadcrumbItem>\n  <BreadcrumbItem as={Link} to=\"/komponenter\">\n    Komponenter\n  </BreadcrumbItem>\n  <BreadcrumbItem as={Link} to=\"/komponenter/navigasjon/breadcrumbs\">\n    Breadcrumbs\n  </BreadcrumbItem>\n</BreadcrumbNavigation>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      BreadcrumbNavigation,
      BreadcrumbItem,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      justifyContent: 'center'
    }} mdxType="Playground">
  <BreadcrumbNavigation mdxType="BreadcrumbNavigation">
    <BreadcrumbItem as={Link} to="/" mdxType="BreadcrumbItem">
      Hjem
    </BreadcrumbItem>
    <BreadcrumbItem as={Link} to="/komponenter" mdxType="BreadcrumbItem">
      Komponenter
    </BreadcrumbItem>
    <BreadcrumbItem as={Link} to="/komponenter/navigasjon/breadcrumbs" mdxType="BreadcrumbItem">
      Breadcrumbs
    </BreadcrumbItem>
  </BreadcrumbNavigation>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "breadcrumbnavigation"
    }}>{`BreadcrumbNavigation`}</h3>
    <ImportStatement imports="BreadcrumbNavigation" mdxType="ImportStatement" />
    <Props of={BreadcrumbNavigation} mdxType="Props" />
    <p><inlineCode parentName="p">{`<BreadcrumbNavigation />`}</inlineCode>{` er en wrapper-komponent som brukes rundt `}<inlineCode parentName="p">{`<BreadcrumbItem />`}</inlineCode>{`-komponenter for å lage en komplett brødsmulesti.`}</p>
    <p>{`Merk at du kun kan sende inn `}<inlineCode parentName="p">{`<BreadcrumbItem />`}</inlineCode>{`s som `}<inlineCode parentName="p">{`children`}</inlineCode>{` til denne komponenten for at den skal fungere som forventet.`}</p>
    <h3 {...{
      "id": "breadcrumbitem"
    }}>{`BreadcrumbItem`}</h3>
    <ImportStatement imports="BreadcrumbItem" mdxType="ImportStatement" />
    <Props of={BreadcrumbItem} mdxType="Props" />
    <p><inlineCode parentName="p">{`<BreadcrumbItem />`}</inlineCode>{` lager en lenke i brødsmulestien. Du sender inn navnet på siden som `}<inlineCode parentName="p">{`children`}</inlineCode>{`, og lenken som `}<inlineCode parentName="p">{`href`}</inlineCode>{`. For å fungere som forventet må de wrappes inne i en `}<inlineCode parentName="p">{`<BreadcrumbNavigation />`}</inlineCode>{`-komponent.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Breadcrumbs er en navigasjonskomponent som viser hvor man er i navigasjonshierarkiet. Bruk breadcrumbs når applikasjonen din har flere nivåer i navigasjonshierarkiet. Med denne komponenten kan du hjelpe brukerne med å navigere raskt mellom disse nivåene. Breadcrumbs er ikke nødvendig hvis applikasjonen din kun har ett navigasjonsnivå.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      